<template>
	<div>
		<div class="wrap" v-if="info.title">
			<div class="header flex-direction-column">
				<span> {{ info.title }}</span>
				<div class="flex">
					<div class="marleft20_time" v-if="info.create_time">{{ info.create_time }}</div>
					<div class="marleft20" v-if="info.file_source">信息源：{{ info.file_source }}</div>
					<div class="marleft20" v-if="info.author">作者：{{ info.author }}</div>
				</div>
				<div class="line_border"></div>
				<div class="zihao" v-if="info.file_font_size">
					<p>
						{{ info.file_font_size }}
					</p>
				</div>
			</div>
			<div class="rich" style="display: inline-block" v-html="info.content"></div>
			<div class="from" v-if="info.source">（来源：{{ info.source }}）</div>
			<div class="enclosure_body" v-if="info.annex.length > 0">
				<span>附件：</span>
				<div class="enclosure">
					<div style="margin-bottom: 5px" @click="annexClick(item)" v-for="(item, index) in info.annex" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="from" v-if="info.signature">{{ info.signature }}</div>
			<div class="from" style="margin-top: 10px" v-if="info.time_date">{{ info.time_date }}</div>
		</div>
		<Lack
			v-if="!info.content"
			text="暂无内容"
			minHeight="400px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('../../../assets/image/img/15.png')"
		></Lack>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import {downloadFile} from '@/utils/tools'
import {postDetail} from '@/service/api/index'
import Lack from "@/components/lack/lack.vue";

export default {
	name: 'nedetail',
	components: {Lack},
	data() {
		return {
			info: {
				content: '',
				title: '',
				create_time: '',
				annex: [],
			}
		}
	},

	mounted() {
		let info = {}
		window.addEventListener('beforeunload', this.beforeunloadFn())
		if (this.$route.params.info) {
			sessionStorage.setItem('params', this.$route.params.info)
			info = JSON.parse(this.$route.params.info)
		} else {
			info = JSON.parse(sessionStorage.getItem('params'))
		}
		this.postDetail(info.id, info.status, 1)
		sessionStorage.setItem('navbarStatus', info.status)
		sessionStorage.setItem('navbarId', info.id)
		sessionStorage.setItem('currentIDChoose', info.id)

		// EventBus.$on('aside', (res) => {
		// 	sessionStorage.setItem('navbarId', res.id)
		// 	sessionStorage.setItem('navbarStatus', res.status)
		// 	this.postDetail(res.id, res.status, 1)
		// })
	},
	destroyed() {
		EventBus.$off('aside')
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postDetail(id, status, type) {
			this.info = ""
			postDetail({id, status, type}).then((res) => {
				this.info = res.msg
			})
		},
		beforeunloadFn() {
			if (!this.$route.query.info && this.$route.path == '/pudetail') {
				let navbarStatus = sessionStorage.getItem('navbarStatus')
				let navbarId = sessionStorage.getItem('navbarId')
				this.postDetail(navbarId, navbarStatus, 1)
			}
		},
		annexClick(item) {
			downloadFile(item.url, item.name)
		}
	},
	watch: {
		'$store.state.listId': {
			handler(val) {
				sessionStorage.setItem('navbarId', val)
				sessionStorage.setItem('currentIDChoose', val)
				this.postDetail(val, 0, 1)
			},
			deep: true,
			immediate: true
		},
	}
}
</script>

<style lang="less" scoped>
.from {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 20px;
	font-size: 16px;
	//font-weight: bold;
	line-height: 25px;
	color: #333;
	margin-top: 20px;
}

.header {
	align-items: center;
	justify-content: center;

	span {
		text-align: center;
		line-height: 40px;
		font-size: 24px;
		font-weight: bold;
	}

	p {
		color: #777777;
		height: 22px;
		font-size: 16px;
		margin-top: 10px;
	}
}

.wrap {
	padding: 30px 44px 40px 44px;
	background: #fff;
	min-height: 400px;

	.enclosure_body {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.enclosure {
			font-size: 16px;
			color: #4786e9;
			padding-bottom: 36px;
			margin-top: 10px;
			cursor: pointer;

			span {
				margin: 0;
				//margin-right: 10px;
				color: #333333;
			}
		}
	}

	.article {
		margin-top: 37px;
		cursor: pointer;

		span {
			height: 25px;
			font-size: 14px;
			line-height: 25px;
			color: #333333;
			margin-bottom: 13px;

			&:hover {
				color: #999999;
			}
		}
	}

}

/deep/ img {
	//width: 100%;
	display: inline-block !important;
}

/deep/ span {
	//width: 100%;
	display: inline-block !important;
}

.zihao {
	width: 100%;
	display: flex;
	justify-content: flex-end;

	p {
		color: #000000;
		height: 22px;
		font-size: 16px;
		margin-top: 18px;
		margin-bottom: 20px;
	}
}

.marleft20_time {
	margin-top: 10px;
	color: #777777;
	height: 22px;
	line-height: 22px;
	font-size: 16px;
}

.marleft20 {
	line-height: 22px;
	margin-left: 20px;
	margin-top: 10px;
	color: #777777;
	height: 22px;
	font-size: 16px;
}
</style>
